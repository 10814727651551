/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { allAppointmentStatusChoices, ticketChoice } from '../../appointments/constants';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import { formatDate } from '../../shared/utils/date.utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from '../../shared/style/siteWideStyles';
import DateErrorsList from '../../appointments/components/DateErrorsList';

const ApproveTicketModal = () => {
  const classes = useStyles();

  const { updateUserTicket, approveTicketModalOpen, setApproveTicketModalOpen, ticketToEdit } = useContext(
    UserTicketsContext,
  );

  const {
    warningsForUnusualDates,
    appointmentFormValues,
    setAppointmentFormValues,
    selectedUnits,
    appointmentDuration,
    setAppointmentDuration,
  } = useContext(AppointmentsContext);

  useEffect(() => {
    setAppointmentDuration(
      (Number(new Date(`${ticketToEdit?.appointment.schedule_date}T${ticketToEdit?.appointment.end_time}`)) -
        Number(new Date(`${ticketToEdit?.appointment.schedule_date}T${ticketToEdit?.appointment.start_time}`))) /
        (15 * 60000),
    );

    setAppointmentFormValues({
      status: 'approved',
      schedule_date: ticketToEdit?.new_data_values?.schedule_date,
      start_time: ticketToEdit?.new_data_values?.start_time,
      end_time: ticketToEdit?.new_data_values?.end_time,
      appointment_status: 'ticket',
    });
  }, [ticketToEdit]);

  const handleFormSubmit = () => {
    updateUserTicket(ticketToEdit, appointmentFormValues);
  };

  const getStatusOptions = () => {
    return [...allAppointmentStatusChoices, ticketChoice];
  };

  useEffect(() => {
    warningsForUnusualDates(ticketToEdit?.appointment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentFormValues, ticketToEdit]);

  return (
    <DeleteModal
      isOpen={approveTicketModalOpen}
      openStatusChanged={setApproveTicketModalOpen}
      onDelete={handleFormSubmit}
      confirmDisabled={appointmentFormValues?.appointment_status === 'ticket'}
      height="800px"
      confirmLabel="Update Status"
    >
      <>
        <Typography
          align="center"
          style={{
            fontSize: '20px',
            marginLeft: '20%',
            marginBottom: '20px',
            width: '60%',
          }}
          variant="h5"
        >
          <CheckCircleIcon style={{ cursor: 'pointer', color: 'green' }} /> &ensp; Change this appointment?
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Ticket Number</strong>: {ticketToEdit?.id}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Timeline ID</strong>: {ticketToEdit?.student_slug}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Ticket Category</strong>: {ticketToEdit?.formatted_category}
            </p>
          </Grid>
          <Grid item xs={6}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>Service Type</strong>: {ticketToEdit?.appointment?.service_type}
            </p>
          </Grid>
          <Grid item xs={6}>
            <p style={{ maxWidth: '250px', margin: 'auto' }}>
              <strong>School</strong>: {ticketToEdit?.payer_name}
            </p>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={12}>
            <p style={{ margin: 0 }}>
              <strong>Original System Error:</strong>
            </p>
            <p style={{ margin: 0 }}>
              {ticketToEdit?.original_error_message ? `Date ${ticketToEdit?.original_error_message}` : 'N/A'}
            </p>
          </Grid>
          <br />
          <Grid item xs={12}>
            <p>
              <strong>Provider Comment:</strong>
            </p>
            <p style={{ margin: 0 }}>{ticketToEdit?.description}</p>
          </Grid>
        </Grid>
        <br />
        {ticketToEdit?.category === 'provider_time_change' && (
          <Grid container justify="center" style={{ marginTop: '10px' }}>
            <Grid item xs={10}>
              <p style={{ width: '100%', margin: 0 }}>
                <span style={{ fontWeight: 'bold' }}>Original Appointment:</span> &ensp;
                {formatDate(ticketToEdit?.appointment?.schedule_date)} &ensp; {ticketToEdit?.appointment?.start_time} to
                &nbsp;
                {ticketToEdit?.appointment?.end_time}
              </p>
            </Grid>
            <Grid item xs={10}>
              <TextInput
                value={appointmentFormValues?.schedule_date}
                styleOverrides={classes.selectInput}
                label="New Appointment Date"
                type="date"
                shrinkLabel
                valueChanged={(value) => setAppointmentFormValues({ ...appointmentFormValues, schedule_date: value })}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                value={appointmentFormValues?.start_time}
                styleOverrides={classes.selectInput}
                label="Start Time"
                type="time"
                shrinkLabel
                valueChanged={(value) => setAppointmentFormValues({ ...appointmentFormValues, start_time: value })}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                value={appointmentFormValues?.end_time}
                styleOverrides={classes.selectInput}
                label="End Time"
                type="time"
                shrinkLabel
                valueChanged={(value) => setAppointmentFormValues({ ...appointmentFormValues, end_time: value })}
              />
            </Grid>
            <Grid item xs={4}>
              <p>{`Selected units: ${selectedUnits}`}</p>
            </Grid>
            {ticketToEdit?.appointment?.editable_units === false && (
              <Grid xs={4}>
                <p>{`Required units: ${appointmentDuration}`}</p>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container justify="center" style={{ marginTop: '10px' }}>
          <p style={{ width: '100%', margin: 0 }}>
            <span style={{ fontWeight: 'bold' }}>Original Status:</span> &ensp;
            {ticketToEdit?.appointment_original_status}
          </p>
          <Grid item xs={10}>
            <SelectInput
              value={appointmentFormValues?.appointment_status}
              styleOverrides={classes.selectInput}
              label="New Status"
              valueChanged={(value) => {
                setAppointmentFormValues({ ...appointmentFormValues, appointment_status: value });
              }}
              choices={getStatusOptions()}
              errorMessage={
                appointmentFormValues?.appointment_status === 'ticket' ? 'must be selected for this appointment' : ''
              }
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: '20px' }}>
            <TextInput
              value={appointmentFormValues?.rejection_message}
              styleOverrides={classes.textInput}
              multiline
              rows={2}
              label="Admin Comment"
              valueChanged={(value: string) =>
                setAppointmentFormValues({ ...appointmentFormValues, rejection_message: value })
              }
            />
          </Grid>
        </Grid>
        <br />
        <DateErrorsList />
        <br />
      </>
    </DeleteModal>
  );
};

export default ApproveTicketModal;
