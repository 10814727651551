import React, { SetStateAction, useState, Dispatch } from 'react';
import { axios } from '../../shared/singletons';
import { Appointment, AppointmentYear } from '../model';
import { Service } from '../../services/model';
import { today } from '../../shared/utils/date.utils';
import { AppointmentChoicesResponse, AppointmentChoices } from '../model';
import { Comment } from '../model';
import { AppointmentFormValues } from '../model';

interface AppointmentsContextInterface {
  fetchAppointments?: (inputFilters?: any, page?: number, sort?: string) => Promise<void>;
  appointments?: Appointment[];
  totalAppointments?: number;

  selectedRows?: Appointment[];
  setSelectedRows?: Dispatch<SetStateAction<Appointment[]>>;

  sortString?: string;
  setSortString?: Dispatch<SetStateAction<string>>;

  handlePageChange?: (page: number) => void;
  handleColumnSort?: (column: Record<string, string>, sortDirection: string) => void;

  fetchAppointment?: (studnetId: string, id: string) => Promise<void>;
  appointment?: Appointment;

  appointmentToEdit?: Appointment;
  setAppointmentToEdit?: Dispatch<SetStateAction<Appointment>>;

  appointmentYear?: AppointmentYear;
  setAppointmentYear?: Dispatch<SetStateAction<AppointmentYear>>;

  appointmentsLoading?: boolean;
  setAppointmentsLoading?: Dispatch<SetStateAction<boolean>>;

  appointmentsDownload?: Appointment[];
  setAppointmentsDownload?: Dispatch<SetStateAction<Appointment[]>>;

  prepareAppointmentsDownload?: () => Promise<void>;
  preparingAppointmentsDownload?: boolean;

  appointmentChoices?: AppointmentChoices;
  fetchAppointmentChoices?: (studentId: string, serviceId?: string) => Promise<void>;

  frontendDateErrors?: string[];
  updateButtonDisabled?: boolean;
  warningsForUnusualDates?: (
    appointment: Appointment,
    currentUserRole?: string,
    allowFuture?: boolean,
    service?: Service,
  ) => void;

  editModalOpen?: boolean;
  setEditModalOpen?: Dispatch<SetStateAction<boolean>>;

  createModalOpen?: boolean;
  setCreateModalOpen?: Dispatch<SetStateAction<boolean>>;

  quickStatusModalOpen?: boolean;
  setQuickStatusModalOpen?: Dispatch<SetStateAction<boolean>>;

  statusForQuickModal?: string;
  setStatusForQuickModal?: Dispatch<SetStateAction<string>>;

  destroyModalOpen?: boolean;
  setDestroyModalOpen?: Dispatch<SetStateAction<boolean>>;

  destroyFutureModalOpen?: boolean;
  setDestroyFutureModalOpen?: Dispatch<SetStateAction<boolean>>;

  appointmentToDelete?: Appointment;
  setAppointmentToDelete?: Dispatch<SetStateAction<Appointment>>;

  commentToEdit?: Comment;
  setCommentToEdit?: Dispatch<SetStateAction<Comment>>;

  editModalInfo?: any;
  setEditModalInfo?: Dispatch<SetStateAction<any>>;

  createModalDate?: string;
  setCreateModalDate?: Dispatch<SetStateAction<string>>;

  formShowing?: boolean;
  setFormShowing?: Dispatch<SetStateAction<boolean>>;

  appointmentFormValues?: AppointmentFormValues;
  setAppointmentFormValues?: Dispatch<SetStateAction<AppointmentFormValues>>;

  selectedUnits?: number;
  setSelectedUnits?: Dispatch<SetStateAction<number>>;

  appointmentDuration?: number;
  setAppointmentDuration?: Dispatch<SetStateAction<number>>;

  filterValues?: Record<string, string>;
  setFilterValues?: Dispatch<SetStateAction<Record<string, string>>>;

  defaultFilterValues?: Record<string, string>;
  setDefaultFilterValues?: Dispatch<SetStateAction<Record<string, string>>>;
}

const AppointmentsContext = React.createContext<AppointmentsContextInterface>({});

const AppointmentsContextConsumer = AppointmentsContext.Consumer;
const AppointmentsContextProvider = ({ children }) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [totalAppointments, setTotalAppointments] = useState<number>(0);
  const [sortString, setSortString] = useState<string>('start_time asc');
  const [appointment, setAppointment] = useState<Appointment>();
  const [appointmentToEdit, setAppointmentToEdit] = useState<Appointment>();
  const [appointmentYear, setAppointmentYear] = useState<AppointmentYear>([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [appointmentsDownload, setAppointmentsDownload] = useState<Appointment[]>([]);
  const [preparingAppointmentsDownload, setPreparingAppointmentsDownload] = useState(false);

  const [appointmentChoices, setAppointmentChoices] = useState<AppointmentChoices>();
  const [frontendDateErrors, setFrontendDateErrors] = useState(['']);
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [quickStatusModalOpen, setQuickStatusModalOpen] = useState(false);
  const [statusForQuickModal, setStatusForQuickModal] = useState('');
  const [destroyModalOpen, setDestroyModalOpen] = useState(false);
  const [destroyFutureModalOpen, setDestroyFutureModalOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState();
  const [commentToEdit, setCommentToEdit] = useState();
  const [editModalInfo, setEditModalInfo] = useState('');
  const [createModalDate, setCreateModalDate] = useState('');
  const [formShowing, setFormShowing] = useState(true);
  const [appointmentFormValues, setAppointmentFormValues] = useState<AppointmentFormValues>({});
  const [selectedUnits, setSelectedUnits] = useState<number>(0);
  const [appointmentDuration, setAppointmentDuration] = useState<number>(0);
  const [filterValues, setFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedRows, setSelectedRows] = useState<Appointment[]>([]);

  const fetchAppointments = async (inputFilters: any = filterValues, page: number = 1, sort: string = sortString) => {
    saveAppointmentFilters(inputFilters);
    setAppointmentsLoading(true);

    try {
      const result = await axios.get('appointments', {
        params: {
          ...formattedFilterParams(inputFilters),
          page: page,
          per: 25,
          sort: sort,
        },
      });

      setAppointments(result?.data?.result ?? []);
      setTotalAppointments(result?.data?.total_records ?? 0);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    } finally {
      setAppointmentsLoading(false);
    }
  };

  const formattedFilterParams = (input: any) => {
    return Object.keys(input).reduce((params, key) => {
      params[key] = input[key];
      return params;
    }, {});
  };

  const handlePageChange = (page: number) => {
    fetchAppointments(filterValues, page, sortString);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    let columnNamesToRansack = {
      student_name: 'patient_last_name',
      school_abbreviation: 'payer_abbr',
      service_type: 'service_name',
      location: 'location_name',
      formatted_schedule_date: 'start_time',
      formatted_start_time: 'start_time',
      formatted_end_time: 'end_time',
      owner_last_name_first_name: 'user_last_name',
      formatted_original_date: 'original_date',
      last_modified: 'updated_at',
      units: 'in_units',
      status: 'status',
    };

    let newSortString = `${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortString(newSortString);
    fetchAppointments(filterValues, 1, newSortString);
  };

  const fetchAppointment = async (studentId: string, id: string) => {
    setAppointmentsLoading(true);
    axios.get(`patients/${studentId}/appointments/${id}.json`).then((result) => {
      setAppointment(result?.data?.result);
      setAppointmentsLoading(false);
    });
  };

  const prepareAppointmentsDownload = async () => {
    setPreparingAppointmentsDownload(true);

    console.log('Preparing');

    try {
      const response = await axios.get('appointments/download', {
        params: {
          ...formattedFilterParams(filterValues),
          page: 1,
          per: 10000,
          sort: sortString,
        },
      });

      setAppointmentsDownload(response?.data?.result);
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
    } finally {
      setPreparingAppointmentsDownload(false);
    }
  };

  const fetchAppointmentChoices = async (studentId: string, serviceId?: string) => {
    axios
      .get<string, AppointmentChoicesResponse>(`patients/${studentId}/appointments/new.json`, {
        params: { referral_service_id: serviceId },
      })
      .then((response) => {
        setAppointmentChoices(response.data.result);
      })
      .catch(() => {
        setAppointmentChoices(null);
      });
  };

  const saveAppointmentFilters = (inputFilters: any) => {
    console.log('inputFilters', inputFilters);
    const savedAppointmentState = {
      filterValues: {
        'ransack[patient_last_name_cont]': inputFilters['ransack[patient_last_name_cont]'],
        'ransack[patient_slug_cont]': inputFilters['ransack[patient_slug_cont]'],
        'ransack[payer_id_eq]': inputFilters['ransack[payer_id_eq]'],
        'ransack[status_eq]': inputFilters['ransack[status_eq]'],
        'ransack[start_time_gteq]': inputFilters['ransack[start_time_gteq]'] || today(),
        'ransack[start_time_lteq]': inputFilters['ransack[start_time_lteq]'] || today(),
        'ransack[service_id_eq]': inputFilters['ransack[service_id_eq]'],
        'ransack[user_id_eq]': inputFilters['ransack[user_id_eq]'],
        'ransack[location_id_eq]': inputFilters['ransack[location_id_eq]'],
        'ransack[blackout_eq]': inputFilters['ransack[blackout_eq]'],
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('appointmentState', JSON.stringify(savedAppointmentState));
  };

  const calculateNewSelectedUnits = (formValueDate) => {
    return (
      (Number(new Date(`${formValueDate}T${appointmentFormValues.end_time}`)) -
        Number(new Date(`${formValueDate}T${appointmentFormValues.start_time}`))) /
      (15 * 60000)
    );
  };

  const changingUnitsFromRequired = (appointment, newSelectedUnits) => {
    return (
      appointment?.editable_units === false &&
      newSelectedUnits !== appointmentDuration &&
      !appointment?.admin_units_override
    );
  };

  const settingUnitsOverMax = (appointment, newSelectedUnits) =>
    newSelectedUnits > appointment?.maximum_units &&
    appointment?.maximum_units !== 0 &&
    !appointment?.admin_units_override;

  const settingUnitsNegative = (newSelectedUnits) => newSelectedUnits <= 0;

  const buildBoundaryDatesObject = (appointment, service) => {
    if (service?.last_day_of_school_year) {
      return {
        first_day_of_school_year: service?.first_day_of_school_year,
        last_day_of_school_year: service?.last_day_of_school_year,
      };
    } else {
      return {
        first_day_of_school_year: appointment?.first_day_of_school_year,
        last_day_of_school_year: appointment?.last_day_of_school_year,
      };
    }
  };

  const boundaryDatesUndefined = (boundaryDates) =>
    !boundaryDates?.first_day_of_school_year || !boundaryDates?.last_day_of_school_year;

  const settingDateOutsideSchoolYear = (formattedDate, appointment) =>
    formattedDate < new Date(appointment?.first_day_of_school_year) ||
    formattedDate > new Date(appointment?.last_day_of_school_year);

  const settingDateOutsideQuarter = (formattedDate, appointment) =>
    formattedDate < new Date(appointment?.first_day_of_quarter) ||
    formattedDate > new Date(appointment?.last_day_of_quarter);

  // TODO: Refactor to use the appointment record from within this context instead of sending it as a parameter
  // First make sure that all references to an appointment record are using the context
  // Can do the same with the currentUserRole
  // But allowFuture should remain a parameter
  const warningsForUnusualDates = (
    appointment: Appointment,
    currentUserRole = 'admin',
    allowFuture = true,
    service = {},
  ) => {
    const today = new Date();
    const formValueDate = appointmentFormValues?.schedule_date;
    const formattedDate = new Date(formValueDate);
    const lastWeek = new Date(today.getTime() - 8 * 24 * 60 * 60 * 1000);
    let hasError = false;

    const newSelectedUnits = calculateNewSelectedUnits(formValueDate);
    const boundaryDates = buildBoundaryDatesObject(appointment, service);

    const messages = [];

    if (formattedDate.getDay() > 4) {
      messages.push('Error: This date is on a weekend');
      hasError = true;
    }

    if (settingUnitsNegative(newSelectedUnits)) {
      messages.push('Error: The appointment must have a positive number of units');
      hasError = true;
    }

    if (settingUnitsOverMax(appointment, newSelectedUnits)) {
      messages.push('Error: This would exceed the maximum units');
      hasError = true;
    }

    if (changingUnitsFromRequired(appointment, newSelectedUnits)) {
      messages.push('Error: This would change the required units');
      hasError = true;
    }

    if (boundaryDatesUndefined(boundaryDates)) {
      messages.push('Error: Dates are not defined for this school year.');
      hasError = true;
    }

    if (settingDateOutsideSchoolYear(formattedDate, appointment)) {
      messages.push('Error: This date is outside the school year.');
      hasError = true;
    }

    if (settingDateOutsideQuarter(formattedDate, appointment)) {
      messages.push('Note: This will move the appointment to a different academic quarter');
      // Not an error
    }

    if (formattedDate < lastWeek) {
      messages.push('Error: This date is more than a week ago.');
      hasError = true;
    }

    if (appointment?.school_closures?.includes(formValueDate)) {
      messages.push('Error: The school is closed on this date');
      hasError = true;
    }

    if (!allowFuture && formattedDate > new Date()) {
      messages.push('Error: This date is in the future.');
      hasError = true;
    }

    if (appointment?.status === 'ticket') {
      messages.push('Error: This appointment has a pending ticket');
      hasError = true;
    }

    if (appointment?.school_inservices?.includes(formValueDate)) {
      messages.push('Note: This is an inservice day.');
      // Not an error
    }

    setSelectedUnits(newSelectedUnits);
    setFrontendDateErrors(messages);
    const shouldDisable = hasError && currentUserRole !== 'admin';
    setUpdateButtonDisabled(shouldDisable);
  };

  return (
    <AppointmentsContext.Provider
      value={{
        fetchAppointments,
        appointments,
        totalAppointments,

        selectedRows,
        setSelectedRows,

        handlePageChange,
        handleColumnSort,

        fetchAppointment,
        appointment,

        appointmentToEdit,
        setAppointmentToEdit,

        appointmentsLoading,
        setAppointmentsLoading,

        appointmentsDownload,
        setAppointmentsDownload,

        prepareAppointmentsDownload,
        preparingAppointmentsDownload,

        appointmentChoices,
        fetchAppointmentChoices,

        warningsForUnusualDates,
        frontendDateErrors,
        updateButtonDisabled,

        editModalOpen,
        setEditModalOpen,

        createModalOpen,
        setCreateModalOpen,

        statusForQuickModal,
        setStatusForQuickModal,

        destroyModalOpen,
        setDestroyModalOpen,

        destroyFutureModalOpen,
        setDestroyFutureModalOpen,

        appointmentToDelete,
        setAppointmentToDelete,

        commentToEdit,
        setCommentToEdit,

        appointmentYear,
        setAppointmentYear,

        editModalInfo,
        setEditModalInfo,

        createModalDate,
        setCreateModalDate,

        quickStatusModalOpen,
        setQuickStatusModalOpen,

        formShowing,
        setFormShowing,

        appointmentFormValues,
        setAppointmentFormValues,

        selectedUnits,
        setSelectedUnits,

        appointmentDuration,
        setAppointmentDuration,

        filterValues,
        setFilterValues,
        defaultFilterValues,
        setDefaultFilterValues,
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};

export { AppointmentsContextProvider, AppointmentsContextConsumer, AppointmentsContext };
