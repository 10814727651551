/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, FC, useEffect, useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import NewAppointmentForm from './NewAppointmentForm';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { appointmentFormStore } from '../../shared/singletons';
import InformationField from '../../shared/components/InformationField';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import DateErrorsList from './DateErrorsList';

interface AddAppointmentsFormModalProps {
  onSuccess?: () => void;
  service?: any;
  isOpen?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const AddAppointmentsFormModal: FC<AddAppointmentsFormModalProps> = (props) => {
  const { isOpen, setOpen, service, onSuccess } = props;
  const { appointmentFormValues, setAppointmentFormValues } = useContext(AppointmentsContext);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);

  const handleSuccess = () => {
    onSuccess();
    setSubmitting(false);
  };

  const allFormValuesPresent = () => {
    return (
      appointmentFormValues?.schedule_date &&
      appointmentFormValues?.location_id &&
      appointmentFormValues?.owner_array?.length > 0 &&
      appointmentFormValues?.start_time &&
      appointmentFormValues?.end_time &&
      (appointmentFormValues?.end_recurrence || appointmentFormValues?.recurrence === 'one_time')
    );
  };

  useEffect(() => {
    if (allFormValuesPresent()) {
      setCreateButtonDisabled(false);
    } else {
      setCreateButtonDisabled(true);
    }
  }, [appointmentFormValues]);

  const handleConfirm = () => {
    setSubmitting(true);

    if (isSubmitting) {
      console.log('Error: Submission in progress');
    } else {
      appointmentFormStore.createOrUpdateAppointment(
        appointmentFormValues,
        appointmentFormValues.student_id,
        null,
        handleSuccess,
      );
    }
  };

  useEffect(() => {
    setAppointmentFormValues({
      student_id: service.student_id,
      owner_array: [''],
      admin_units_override: 'false',
      start_time: '00:00',
      end_time: '00:00',
      recurrence: 'one_time',
      referral_service_id: service?.id,
      location_id: service?.student?.location_id,
    });
  }, [service]);

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title="Create New Appointment"
      onConfirm={handleConfirm}
      confirmDisabled={createButtonDisabled}
      confirmLabel={isSubmitting ? 'Creating...' : 'Create Appointment'}
      openStatusChanged={(isOpen) => setOpen(isOpen)}
    >
      <>
        <Grid container spacing={6}>
          <Grid item>
            <InformationField label="Student" value={service?.student_name} />
          </Grid>
          <Grid item>
            <InformationField label="Timeline ID" value={service?.student_slug} />
          </Grid>
          <Grid item>
            <InformationField label="Service Type" value={service?.service_type} />
          </Grid>
        </Grid>
        <br />
        <DateErrorsList />
        <br />
        <NewAppointmentForm service={service} isLoading={false} />
        <br />
        <DateErrorsList />
      </>
    </ConfirmCancelModal>
  );
};

export default AddAppointmentsFormModal;
