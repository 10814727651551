import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import { useHistory } from 'react-router-dom';
import 'jquery-ui-bundle';
import { CSVLink } from 'react-csv';
import format from 'date-fns/format';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Service } from '../../services/model';
import { serviceFormStore } from '../../shared/singletons';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { FilterProps } from '../../shared/components/FilterBar';
import { UserContext } from '../../auth/contexts/userContext';
import { ServicesContext } from '../../services/contexts/ServicesContexts';
import ReferralsFilterBar from './ReferralsFilterBar';

interface ReferralsListProps {
  showCreateButton?: boolean;
  showFilterBar?: boolean;
  initialFilter?: { [key: string]: string };
}

// Can remove the pagination and sortServer props from the ReferralsListProps interface
const ReferralsList: FunctionComponent<ReferralsListProps> = (props) => {
  const { showCreateButton = false, showFilterBar = false, initialFilter } = props;

  const {
    phaseColors,
    schoolChoices,
    serviceTypeChoices,
    ownerChoices,
    stageChoices,
    statusChoices,
  } = React.useContext(ServiceFormOptionsContext);

  const {
    fetchReferrals,
    servicesDownload,
    services,
    servicesLoading,
    totalServices,
    filterValues,
    setSortValue,
  } = useContext(ServicesContext);

  const history = useHistory();
  const { currentUserHasRole, currentUserHasAnyRole } = useContext(UserContext);
  const [referralsFetched, setReferralsFetched] = useState<boolean>(false);

  const filters: FilterProps[] = [
    {
      selector: 'ransack[patient_slug_i_cont]',
      label: 'Timeline ID',
      type: 'text',
    },
    {
      selector: 'ransack[patient_last_name_i_cont]',
      label: 'Student Last Name',
      type: 'select',
    },
    {
      selector: 'ransack[payer_id_eq]',
      label: 'School',
      type: 'text',
      options: schoolChoices,
    },
    {
      selector: 'ransack[service_id_eq]',
      label: 'Service Type',
      type: 'select',
      options: serviceTypeChoices,
    },
    {
      selector: 'ransack[users_id_eq]',
      label: 'Owner',
      type: 'select',
      advanced: true,
      options: ownerChoices,
    },
    {
      selector: 'ransack[referral_stage_id_eq]',
      label: 'Stage',
      type: 'select',
      options: stageChoices,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'text',
      options: statusChoices,
    },
  ];

  const schoolAdminFilters: FilterProps[] = [
    {
      selector: 'ransack[patient_last_name_i_cont]',
      label: 'Student Last Name',
      type: 'select',
    },
    {
      selector: 'ransack[service_id_eq]',
      label: 'Service Type',
      type: 'select',
      options: serviceTypeChoices,
    },
    {
      selector: 'ransack[referral_stage_id_eq]',
      label: 'Stage',
      type: 'select',
      options: stageChoices,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'text',
      options: statusChoices,
    },
  ];

  const columns: IDataTableColumn<Service>[] = [
    {
      name: 'PHASE',
      selector: 'phase',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: phaseColors[row.phase],
          }}
        >
          {row.phase === 'patient' ? 'student' : row.phase}
        </div>
      ),
    },
    {
      name: 'STUDENT NAME',
      selector: 'student_name',
      sortable: true,
      cell: (row) => (
        <div>
          <a href={`/students/${row.student_id}/profile`}>{row.student_name}</a>
        </div>
      ),
    },
    {
      name: 'TIMELINE ID',
      selector: 'student_slug',
      sortable: true,
      omit: currentUserHasRole('school_admin'),
    },
    {
      name: 'SCHOOL',
      selector: 'school_abbreviation',
      sortable: true,
      grow: 0.75,
      omit: currentUserHasRole('school_admin'),
    },
    {
      name: 'SERVICE TYPE',
      selector: 'service_type',
      sortable: true,
      cell: (row) => (
        <div>
          {currentUserHasRole('school_admin') ? (
            <p>{row.service_type}</p>
          ) : (
            <a href={`/students/${row.student_id}/services/${row.id}`}>{row.service_type}</a>
          )}
        </div>
      ),
    },
    {
      name: 'OWNER',
      selector: 'owner',
      sortable: true,
      omit: currentUserHasAnyRole(['school_admin', 'service_provider']),
    },
    {
      name: 'STAGE',
      selector: 'stage',
      sortable: true,
      grow: 1.5,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            color: row.timeline_color,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {row.timeline_color === 'red' && (
            <HourglassDisabledIcon style={{ maxHeight: '90%', maxWidth: '70%', color: 'red' }} />
          )}
          {row.stage || 'Missing Stage'}
        </div>
      ),
    },
    {
      name: 'TIMELINE',
      selector: 'timeline',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: row.timeline_color,
            fontSize: '16px',
          }}
        >
          {row.timeline}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!referralsFetched) {
      fetchReferrals(initialFilter);
      setReferralsFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralsFetched]);

  const handlePageChange = (page: number) => {
    fetchReferrals(filterValues, page);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      phase: 'phase',
      school_abbreviation: 'payer_abbr',
      student_slug: 'patient_slug',
      student_name: 'patient_last_name',
      service_type: 'service_name',
      date_created: 'created_at',
      owner: 'users_last_name',
      stage: 'referral_stage_name',
      timeline: 'initial_referral_date',
      status: 'status',
    };

    const newSort = `${columnNamesToRansack[column.selector]} ${sortDirection}`;
    setSortValue(newSort);
    fetchReferrals(filterValues, 1, newSort);
  };

  return (
    <Box>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Referrals
          </Typography>
        </Grid>
        <Grid item>
          {showCreateButton && currentUserHasAnyRole(['admin', 'school_admin']) && (
            <Button variant="contained" color="primary" onClick={() => serviceFormStore.setCreateModalOpen(true)}>
              Create New Service +
            </Button>
          )}
          &ensp;
          {servicesDownload && (
            <CSVLink
              data={servicesDownload}
              filename={`referrals-${format(new Date(), 'MM-dd-yyyy')}.csv`}
              className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary`}
              style={{ marginLeft: '8px', backgroundColor: '#424E7A', color: '#fff' }}
            >
              Download Referrals
            </CSVLink>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <ReferralsFilterBar
              filters={
                currentUserHasRole('school_admin') ? schoolAdminFilters : filters.filter((filter) => !filter.advanced)
              }
              advancedFilters={
                currentUserHasAnyRole(['admin', 'supervisor']) ? filters.filter((filter) => filter.advanced) : []
              }
              initialFilter={initialFilter}
            />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={services}
            progressPending={servicesLoading}
            striped
            onRowClicked={(service: Service) => currentUserHasRole('school_admin') ? null : history.push(`/students/${service.student_id}/services/${service.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalServices}
            onChangePage={handlePageChange}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReferralsList;
